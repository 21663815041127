import {fontAwesomeIcons} from './core/services/config-services/font-awesome-icons';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TableModule} from 'primeng/table';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgxUiLoaderConfig, NgxUiLoaderModule} from 'ngx-ui-loader';
import {httpInterceptorProviders} from './core/services/interceptors-service';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {ToastrModule} from 'ngx-toastr';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {initializeKeycloak} from './init/keycloak-init.factory';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FileUploadModule} from '@iplab/ngx-file-upload';
import {FormsModule} from "@angular/forms";
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ProfileLayoutComponent} from './layouts/profile-layout/profile-layout.component';
import {TabMenuModule} from "primeng/tabmenu";
import {MenuModule} from 'primeng/menu';
import {BadgeModule} from 'primeng/badge';
import {SharedModule} from './shared/shared.module';
import {AdminLayoutComponent} from "./layouts/admin/admin-layout.component";
import {DefaultLayoutComponent} from './layouts/default-layout/default-layout.component';
import {IframeLayoutComponent} from './layouts/iframe-layout/iframe-layout.component';
import {NgOptimizedImage} from "@angular/common";
import {HeaderComponent} from './components/header/header.component';
import {AvatarModule} from "primeng/avatar";
import {ButtonModule} from "primeng/button";
import {StyleClassModule} from "primeng/styleclass";
import {RippleModule} from "primeng/ripple";
import {NotFoundComponent} from './pages/error/not-found/not-found.component';


// AoT requires an exported function for factories
// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http);
// }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "blur": 2,
  "delay": 0,
  "fastFadeOut": true,
  "fgsColor": "#00828f",
  "fgsPosition": "bottom-right",
  "fgsSize": 90,
  "fgsType": "ball-spin-clockwise",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 60,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40,40,40,0.32)",
  "pbColor": "#00828f",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": true,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 500
}

@NgModule({
  declarations: [
    AppComponent,
    ProfileLayoutComponent,
    AdminLayoutComponent,
    DefaultLayoutComponent,
    IframeLayoutComponent,
    HeaderComponent,
    NotFoundComponent
  ],
  imports: [

    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TableModule,
    SharedModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxWebstorageModule.forRoot({prefix: 'matchIT', separator: '-', caseSensitive: true}),
    KeycloakAngularModule,
    ToastrModule.forRoot(),
    FileUploadModule,
    FormsModule,
    FontAwesomeModule,
    TabMenuModule,
    MenuModule,
    BadgeModule,
    NgOptimizedImage,
    AvatarModule,
    ButtonModule,
    StyleClassModule,
    RippleModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'en'},
    httpInterceptorProviders,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
  ],
  bootstrap: [AppComponent],
  exports: [
    NotFoundComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(iconLibrary: FaIconLibrary) {
    iconLibrary.addIcons(...fontAwesomeIcons);
  }
}
