<div class="mt-2" id="my-enterprise-page">
  <div class="container p-0">
    <p-tabMenu [model]="routes" [scrollable]="true">
      <ng-template pTemplate="item" let-item>
        {{item.label}}
      </ng-template>
    </p-tabMenu>
  </div>
  <router-outlet></router-outlet>
</div>
