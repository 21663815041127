import {BillingService} from '@app/core/services/billing/billing.service';
import {Component, HostListener, OnInit} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {LocalStoreService} from '@app/core/services/local-store.service';
import {MenuItem} from 'primeng/api';
import {AccountService} from '@app/core/services/auth-services/account.service';
import {DomSanitizer} from '@angular/platform-browser';
import {CurrentSubscription} from '@app/core/models/current-subscription.models';
import {AuthorityEnum} from '@app/core/enumerations/authority-enums';
import {NotificationService} from '@app/core/services/notification/notification.service';
import {mergeMap} from "rxjs/operators";
import {interval} from "rxjs";


@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  isConnected: boolean = false;
  items: MenuItem[];
  currentUrl: any = 'assets/images/avatar.png';
  notificationCount = 0;
  currentPlan: CurrentSubscription | null = null;
  authorityEnum = AuthorityEnum;

  constructor(
    private keycloakService: KeycloakService,
    private localStoreService: LocalStoreService,
    private accountService: AccountService,
    private sanitizer: DomSanitizer,
    private noticationService: NotificationService,
    private billingService: BillingService,
  ) {
    this.items = [
      {
        label: 'Se déconnecter',
        icon: 'pi pi-signout',
        command: () => {
          this.logout();
        }
      }
    ];
  }

  ngOnInit(): void {
    this.isConnected = Boolean(this.keycloakService.getKeycloakInstance().authenticated);
    if (this.isConnected) {
      this.initUnreadNotificationsCount();
      this.getImageProfile();
      this.accountService.subImageProfileSubject().subscribe({
        next: () => {
          this.getImageProfile();
        }
      });

      this.noticationService.getNotReadNumber().subscribe(
        num => this.notificationCount = num
      );
      this.billingService.getCurrentSubscription().subscribe({
        next: (data: any) => this.currentPlan = data
      });
    }
  }

  initUnreadNotificationsCount() {
    interval(30 * 1000) // toutes les 30 secondes
      .pipe(
        mergeMap(() => this.noticationService.getNotReadNumber())
      )
      .subscribe(num => this.notificationCount = num);
  }

  getImageProfile() {
    this.accountService.getUserProfileImage().subscribe({
        next: (userImage: ArrayBuffer) => {
          if (userImage) {
            const imageBlob = new Blob([userImage], { type: 'image/*' });
            const imageUrl = URL.createObjectURL(imageBlob);
            this.currentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
          }
        },
        error: (errorResponse: any) => console.log('ERROR!!! ', errorResponse)
      });
  }

  login() {
    this.keycloakService.login({redirectUri: `${window.location.origin}/auth/callback/keycloak/login?returnUrl=${window.location.pathname}`});
  }

  register() {
    this.keycloakService.register({redirectUri: `${window.location.origin}/auth/callback/keycloak/register?returnUrl=${window.location.pathname}`});
  }

  logout() {
    this.localStoreService.clearAll();
    this.keycloakService.logout(window.location.origin);
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const isMenuOpen = document.getElementById('navbarNavDropdown')?.classList.contains('show');
    if (isMenuOpen) {
      // Check if the click target is inside the navigation menu
      const menu = document.querySelector('.navbar.nav-content');
      if (menu && !menu.contains(event.target as Node)) {
        // Click occurred outside of the menu, close it
        document.getElementById('navbarNavDropdownButton')?.click();
      } else if (menu && menu.contains(event.target as Node)) {
        const clickedElement = event.target as HTMLElement;
        // Click occurred inside of the menu, close it
        if (clickedElement.tagName.toLowerCase() === 'a') {
        // Click occurred on a <a> element
          document.getElementById('navbarNavDropdownButton')?.click();
        }
      }
    }
  }

  calculFreePlanDaysDifference(): number {
    if (!this.currentPlan || !this.currentPlan.createDate || !this.currentPlan.expiringDate) {
      return 0;
    }

    const createDate = new Date(this.currentPlan.createDate);
    const expiringDate = new Date(this.currentPlan.expiringDate);

    const timeDifference = expiringDate.getTime() - createDate.getTime();
    return Math.floor(timeDifference / (1000 * 3600 * 24));
  }
}
