import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {ApplicationConfigService} from 'src/app/core/services/config-services/application-config.service';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  protected resourceUrl = '';
  protected http: HttpClient;
  protected applicationConfigService: ApplicationConfigService;

  constructor(baseUrl: String) {
    this.http = inject(HttpClient);
    this.applicationConfigService = inject(ApplicationConfigService)
    this.resourceUrl = this.applicationConfigService.getEndpointFor('api/' + baseUrl);
  }

  /**
   * Transforming object to javascript FormData
   * @param obj
   * @return FormData
   */
  objectToFormData(obj: object): FormData {
    return Object.entries(obj).reduce((formData, [key, value]) => {
      formData.append(key, value);
      return formData;
    }, new FormData());
  }
}
