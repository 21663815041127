import {Injectable} from '@angular/core';
import {Router, UrlTree} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {

  constructor(
    protected  override router: Router,
    protected readonly keycloakService: KeycloakService,
  ) {
    super(router, keycloakService);
  }

  async isAccessAllowed(): Promise<boolean | UrlTree> {

    if (!this.authenticated) {
      await this.keycloakService.login({
        redirectUri: window.location.href
      });
    }

    return this.authenticated;
  }
}
