import {Component, OnInit} from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { AuthorityEnum } from 'src/app/core/enumerations/authority-enums';

@Component({
  selector: 'app-parametre-layout',
  templateUrl: './parametre-layout.component.html',
  styleUrls: ['./parametre-layout.component.scss']
})
export class ParametreLayoutComponent implements OnInit {
  routes!: object[];
  roles: string[] = [];

  constructor(private keycloakService: KeycloakService) {
    this.roles = this.keycloakService.getUserRoles();
  }

  hasAnyAuthority(authorities: string[] | string): boolean {
    return this.roles.some((authority: string) => authorities.includes(authority));
  }

  ngOnInit(): void {
    this.routes = [
      {
        label: 'Mon profil',
        routerLink: '/parametres/profil',
        disabled: !this.hasAnyAuthority([AuthorityEnum.PAGE_EMAIL_TEMPLATE])
      },
      {
        label: 'Mon Entreprise',
        routerLink: '/parametres',
        routerLinkActiveOptions: {exact: true},
        disabled: !this.hasAnyAuthority([AuthorityEnum.PAGE_COMPANY])
      },
      {
        label: 'Abonnements/Facture',
        routerLink: '/parametres/subscriptions',
        disabled: !this.hasAnyAuthority([AuthorityEnum.PAGE_PACKAGE])
      },
      {
        label: 'Droits et Utilisateurs',
        routerLink: '/parametres/utilisateurs',
        disabled: !this.hasAnyAuthority([AuthorityEnum.PAGE_ROLE])
      },
      {
        label: 'Template CV',
        routerLink: '/parametres/models-cv',
        disabled: !this.hasAnyAuthority([AuthorityEnum.PAGE_COMPANY_CV_TEMPLATE])
      },
    ]
  }
}
