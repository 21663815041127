import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LocalStoreService {

  private ls = window.localStorage;
  constructor(private router : Router) { }

  public setItem(key: any, value: any) {
    value = JSON.stringify(value);
    this.ls.setItem(key, value);
    return true;
  }

  public getItem(key: any) {
    const value = this.ls.getItem(key);
    if(value){
      try {
        return JSON.parse(value);
      } catch (e) {
        return null;
      }
    }
    return null;
  }
  public clearAll() {
    this.ls.clear();
  }

  public clear(key: any) {
    this.ls.removeItem(key);
  }
}
