<!--
//////////////////////////////////
/** Welcome **/
//////////////////////////////////
-->
<nav class="navbar navbar-expand-lg nav-content">
  <a class="navbar-brand">
    <img priority width="120" ngSrc="../../../assets/images/logo/logo-dark.png" alt="Logo Match-it" height="45"/>
  </a>
  <button class="navbar-toggler" type="button" id="navbarNavDropdownButton" data-toggle="collapse"
    data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
    aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavDropdown">
    <ul class="navbar-nav mx-auto">
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/accueil">{{ 'Navbar.Home' | translate }}</a>
      </li>
      <div *jhiHasAnyAuthority="[authorityEnum.PAGE_DASHBOARD]">
        <li *ngIf="isConnected" class="nav-item custom-width">
          <a class="nav-link" routerLinkActive="active" routerLink="/dashboard">{{ 'Navbar.Dashboard' | translate }}</a>
        </li>
      </div>
      <div>
        <li *ngIf="isConnected" class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/parametres">{{ 'Navbar.Example' | translate }}</a>
        </li>
      </div>
      <div *jhiHasAnyAuthority="[authorityEnum.ADMIN_MATCHIT]">
        <li *ngIf="isConnected" class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/admin">{{ 'Navbar.Admin' | translate }}</a>
        </li>
      </div>
      <li *jhiHasAnyAuthority="[authorityEnum.RECRUITER]">
        <a class="nav-link" routerLinkActive="active" routerLink="/sourcing/list-consultants">{{ 'Navbar.Candidats' |
          translate }}</a>
      </li>
      <div *jhiHasAnyAuthority="[authorityEnum.RECRUITER]">
        <li class="nav-item custom-width-jobs">
          <a class="nav-link" routerLinkActive="active" routerLink="/jobs/list">{{ 'Navbar.Jobs' | translate }}</a>
        </li>
      </div>
    </ul>
    <ul class="navbar-nav btn-actions">
      <ng-container *ngIf="isConnected; else notConnected">
        <li class="btn-actions-user">
          <button class="btn mr-2" *ngIf="currentPlan && currentPlan.planPackage.type == 'FREE'">
            version gratuite : {{ calculFreePlanDaysDifference() }} jours restants
          </button>
          <button class="btn btn-outline-secondary user-notifs mr-1" routerLink="/notifications">
            <span class="p-overlay-badge">
              <i class="pi pi-bell"></i>
              <p-badge *ngIf="notificationCount > 0" [value]="notificationCount.toString()"></p-badge>
            </span>
          </button>
          <button class="btn btn-outline-secondary user-profile mr-1" (click)="menu.toggle($event)">
            <img [src]="currentUrl" alt="User Avatar"/>
          </button>
          <p-menu #menu [model]="items" [popup]="true"></p-menu>
        </li>
      </ng-container>
      <ng-template #notConnected>
        <li class="btn-actions-access">
          <button class="btn btn-secondary mr-1" (click)="register()">{{ 'Navbar.SignUp' |
            translate
            }}</button>
          <button class="btn btn-outline-secondary" (click)="login()">{{ 'Navbar.SignIn' |
            translate
            }}</button>
        </li>
      </ng-template>
    </ul>
  </div>
</nav>
