import {Component, OnInit} from '@angular/core';
import {KeycloakService} from "keycloak-angular";
import {AuthorityEnum} from "@app/core/enumerations/authority-enums";
import {interval} from "rxjs";
import {mergeMap} from "rxjs/operators";
import {NotificationService} from "@app/core/services/notification/notification.service";
import {AccountService} from "@app/core/services/auth-services/account.service";
import {DomSanitizer} from "@angular/platform-browser";
import {UserProfile} from "@app/core/models/user-profile.models";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isConnected: boolean = false;
  notificationCount: number = 0;
  user!: UserProfile;
  currentUrl: any = 'assets/images/avatar.png';

  constructor(
    private keycloakService: KeycloakService,
    private accountService: AccountService,
    private sanitizer: DomSanitizer,
    private notificationService: NotificationService,
  ) {
  }

  ngOnInit(): void {
    this.isConnected = Boolean(this.keycloakService.getKeycloakInstance().authenticated);

    if (this.isConnected) {
      this.initUnreadNotificationsCount();
      this.getUserProfileData();
      this.getImageProfile();
    }
  }

  getImageProfile() {
    this.accountService.getUserProfileImage().subscribe({
      next: (userImage: ArrayBuffer) => {
        if (userImage) {
          const imageBlob = new Blob([userImage], {type: 'image/*'});
          const imageUrl = URL.createObjectURL(imageBlob);
          this.currentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
        }
      },
      error: (errorResponse: any) => console.log('ERROR!!! ', errorResponse)
    });
  }

  getUserProfileData() {
    this.accountService.getUserProfile().subscribe((data) => {
      this.user = data;
    });
  }

  initUnreadNotificationsCount() {
    interval(30 * 1000) // toutes les 30 secondes
      .pipe(
        mergeMap(() => this.notificationService.getNotReadNumber())
      )
      .subscribe(num => this.notificationCount = num);
  }

  login() {
    this.keycloakService.login({redirectUri: `${window.location.origin}/auth/callback/keycloak/login?returnUrl=${window.location.pathname}`});
  }

  register() {
    this.keycloakService.register({redirectUri: `${window.location.origin}/auth/callback/keycloak/register?returnUrl=${window.location.pathname}`});
  }

  logout() {
    this.keycloakService.logout(window.location.origin);
  }

  protected readonly authorityEnum = AuthorityEnum;
  protected readonly window = window;
}
