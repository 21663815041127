import { KeycloakService } from "keycloak-angular";
import { environment } from 'src/environments/environment';

export function initializeKeycloak(keycloak: KeycloakService) {
  return async () => {
    console.trace('Avant l\'initialisation de Keycloak');
    try {
      const success = await keycloak
        .init({
          config: {
            url: environment.Keycloak.url,
            realm: environment.Keycloak.realm,
            clientId: environment.Keycloak.clientId,
          },
          loadUserProfileAtStartUp: true,
          initOptions: {
            onLoad: 'check-sso',
            checkLoginIframe: false,
          },
        });
      console.trace(`Keycloak init returned ${success}`);
      if (success) {
        console.trace('Keycloak a réussi à s\'initialiser.');
      } else {
        console.trace('Keycloak a échoué à s\'initialiser.');
      }
    } catch (e) {
      console.error('Erreur lors de l\'initialisation de Keycloak :', e);
    }
  };
}
