import { JobsModule } from './pages/jobs/jobs.module';
import { AuthComponent } from './pages/auth/auth.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { navbarRoute } from '../app/layouts/nav-bar/nav-bar.route';
import { AuthGuard } from './guard/auth.guard';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { IframeLayoutComponent } from './layouts/iframe-layout/iframe-layout.component';

const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: 'accueil',
        loadChildren: () => import(`./pages/home/home.module`).then(m => m.HomeModule),
      },
      {
        path: 'after-register',
        loadChildren: () => import(`./pages/after-register/after-register.module`).then(m => m.AfterRegisterModule),
      },
      {
        path: 'auth',
        component: AuthComponent,
        loadChildren: () => import(`./pages/auth/auth.module`).then(m => m.AuthModule),
      },
      {
        path: 'dashboard',
        loadChildren: () => import(`./pages/dashboard/dashboard.module`).then(m => m.DashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'sourcing',
        loadChildren: () => import(`./pages/sourcing/sourcing.module`).then(m => m.SourcingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'jobs',
        loadChildren: () => import(`./pages/jobs/jobs.module`).then(m => m.JobsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'parametres',
        loadChildren: () => import(`./pages/parametre/parametre.module`).then(m => m.ParametreModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'stripe/callback',
        children: [
          {
            path: 'success',
            redirectTo: '/parametres/subscriptions?action=register&status=success',
            pathMatch: 'full',
          },
          {
            path: 'error',
            redirectTo: '/parametres/subscriptions?action=register&status=error',
            pathMatch: 'full',
          },
        ],
        canActivate: [AuthGuard]
      },
      {
        path: 'notifications',
        loadChildren: () => import(`./pages/notifications/notifications.module`).then(m => m.NotificationsModule),
        // canActivate: [AuthGuard]
      },
      {
        path: '',
        redirectTo: '/accueil',
        pathMatch: 'full'
      },
      {
        path: 'admin',
        loadChildren: () => import(`./pages/admin/admin.module`).then(m => m.AdminModule),
        // canActivate: [AuthGuard]
      },
      navbarRoute
    ]
  },
  {
    path: 'iframe',
    component: IframeLayoutComponent,
    loadChildren: () => import(`./pages/iframe/iframe.module`).then(m => m.IframeModule),
    canActivate: []
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
