<ngx-ui-loader></ngx-ui-loader>
<div>
  <router-outlet name="navbar" *ngIf="!isAuthPage()"></router-outlet>
</div>

<div class="container-fluid page-content">
  <div class="mb-5 mt-2">
    <router-outlet></router-outlet>
  </div>

</div>